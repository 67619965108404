$color_list: (
  primary: rgb(153, 153, 153),
  secondary: #FCFCFC,
  ternary: #FF9026,
  quad: #000000,
  validated: #00a800,
  input_bg: rgb(237, 237, 237),
  background: #e1e4e6,
  text-color: darkgrey,
  light-grey: #666666,
  light-grey-op1: #676767,
  light-grey-op: #66666640,
  light-grey-op-1: #66666600,
  input-field-box: #f7f7f7,
  light_orange: #ff872605,
  make-it-black: #231F20,
  error: #ff3d26,
  bee-primary: #FF8D26,
  grayColor: #aaaaaa,
  highlight: #1cb5e0
);

// Font-sizes
$font-list: (
  xs: 1rem,
  sm: 1.2rem,
  md: 1.4rem,
  lg: 1.6rem,
  xl: 1.8rem,
  h1: 2.6rem,
  h2: 2.4rem,
  h5: 1.8rem
);


// Font-sizes
$design-constant: (
  radius: 10px,
  border-stroke: 1px,
  input-padding-top-bottom: 5px,
  input-padding-left-right: 20px,
  popup-padding: 0px 60px 30px 60px,
  input_radius: 12px,
  input_height: 45px,
  button_height: 50px,
  text_area: 100px,
  item_radius: 15px
);


// size of screens for media quiries
$phone-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
  @media (max-width: #{phone-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$item-width: 252px;
$item-width-margin: 10px;
$item-four-wide-width: ($item-width * 4) + ( $item-width-margin * 8) - ($item-width-margin * 2)
