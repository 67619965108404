@font-face {
  font-family: "montserrat";
  src: url("../assets/fonts/MontserratAlternates-Regular.woff2");
  font-weight: normal;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: "montserrat";
  src: url("../assets/fonts/MontserratAlternates-Bold.woff2");
  font-weight: bold;
  font-display: block;
  font-style: normal;
}

// Nunito
@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Regular.woff2");
  font-weight: normal;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Bold.woff2");
  font-weight: bold;
  font-display: block;
  font-style: normal;
}
