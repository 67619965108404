@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid"; // add the grid
@import "~bootstrap/scss/utilities"; // add css utilities
@import "~bootstrap/dist/css/bootstrap.min.css";


* {
  &:active,
  :focus {
    outline: none;
    box-shadow: none;
  }
}

label {
  margin-bottom: 0;
}

ol, ul, dl {
  margin-bottom: 0;
}

html, body {
  width: 100%;
  height: 100%;
}
