/* First so that it is overriden */
@import "~src/theme/bootstrap";
@import "~ngx-toastr/toastr.css";
@import "./theme/variables";
@import "~src/theme/fonts";
@import "fonts";

@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~ngx-sharebuttons/themes/modern";

/************************************ GLOBAL CLASSES *********************************************************/

html {
  overflow-x: hidden;
  font-size: 13px;
}

@media screen and (min-width: $phone-width) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "Nunito", sans-serif;
}

.bee-btn {
  &__tertiary {
    background: transparent;
    text-decoration: underline;
    color: black;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

.rippler-input {
  border: 0;
}

.align_center {
  text-align: center;
}

.mr-lft-15 {
  margin-left: 15px;
}

.on-active {
  &:hover {
    color: #ff9026;
  }
}

.active-menu {
  color: #ff9026;
}

.hive_pending__popup {
  padding: 1rem 1rem;
}

.add-margin-btm-15 {
  margin-bottom: 15px;
}

.close-button {
  margin-top: 30px;
  margin-bottom: 0 !important;
  text-align: center;
  color: map_get($color_list, bee-primary);
  cursor: pointer;

  &.no_margin {
    margin-top: 10px;
  }
}

.align_item_center {
  align-items: center;
  text-align: center;
  align-self: center;
}

.t_r_c {
  font-family: Nunito, sans-serif;
  font-size: 16px;
}

.text_orange {
  color: map_get($color_list, bee-primary);
}

.text_green {
  color: map_get($color_list, validated);
}

.t_r_c_bold {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.privacy_policy_bold {
  font-family: Nunito, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.bold_text {
  font-weight: 600;
}

.t_r_c_1 {
  font-family: Nunito, sans-serif;
  font-size: 16px;
}

body {
  padding: 0;
  margin: 0;
  color: #202020;
  font-family: Nunito, sans-serif;
}

dynamic-iput {
  .input-field {
    border: 0;
  }
}

.password-recovery {
  font-size: map_get($font-list, sm);
  color: map_get($color_list, quad);
  font-family: "Nunito", sans-serif;
  align-items: center;
}

.checkbox-container {
  width: 20px !important;
  height: 16px !important;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-evenly !important;
  justify-content: space-between;
  margin-top: 4%;

  &.space__between {
    justify-content: space-between;
  }

  &.align_center {
    justify-content: space-evenly !important;
  }

  &.at_end {
    justify-content: flex-end !important;
  }

  &--centre {
    justify-content: center;
  }

  &.remove_margin {
    margin-top: 0px !important;
  }

  .button {
    font-size: map_get($font-list, md);
    padding-top: map_get($font-list, xs);
    padding-bottom: map_get($font-list, xs);
    border-radius: map_get($design-constant, input_radius);
    background: transparent;
    padding-left: map_get($font-list, md);
    padding-right: map_get($font-list, md);
    color: map_get($color_list, light-grey);
    border: map_get($design-constant, border-stroke) solid
      map_get($color_list, light-grey);
    height: map_get($design-constant, button_height);
    transition: transform 0.2s ease-out;
    cursor: pointer;

    &:hover {
      background-color: map_get($color_list, primary);
      color: map_get($color_list, secondary);
    }

    &:active {
      background-color: map_get($color_list, primary);
      color: map_get($color_list, secondary);
    }

    &:disabled {
      background-color: map_get($color_list, primary);
      color: map_get($color_list, secondary);
      transition: none;
      border: none;
      cursor: default;

      &:hover {
        border: none;
      }
    }

    &:hover:enabled {
      background-color: map_get($color_list, bee-primary);
      color: map_get($color_list, secondary);
    }

    &.add-margin {
      margin: 20px;
    }
  }

  @media screen and (max-width: $phone-width) {
    .button {
      margin: 1rem;
    }
  }
}

/***************************************** OVERWRITING BUTTON CLASS OF USER MODULEL FROM RIPPLER *******************/
.home .button.active {
  border: none !important;
}

/******************************************** GLOBAL LABEL ********************************************************/
.label {
  font-size: map_get($font-list, sm);
  color: map_get($color_list, text-color);
  font-family: "Nunito", sans-serif;
  text-transform: lowercase !important;

  @each $name, $size in $font-list {
    &.#{$name} {
      font-size: $size;
    }
  }

  @each $name, $color in $color_list {
    &.#{$name} {
      color: $color;
    }
  }

  .icon {
    display: none;
  }
}

.text_normal {
  text-transform: none !important;

  &.font-sm {
    font-size: 16px;
  }

  &.txt-prm {
    color: #ff8726;
  }
}

.font_nu {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
}

.font-mont {
  font-family: "montserrat", sans-serif;
}

.primary_text {
  color: map_get($color_list, bee-primary);
}

.add-anchor {
  cursor: pointer;
}

.custom-login-component {
  width: auto;

  lib-app-profile {
    .image-form-container {
      padding-bottom: 25px !important;
    }
  }

  .sign-in-page,
  .forgot-password-page,
  .sign-up-page,
  .lib-app-profile {
    font-family: montserrat, sans-serif;
    padding: 0;
    height: auto;
  }

  .full-page {
    background: transparent;
    height: auto;
  }

  .image-form-container {
    width: auto;

    .content_image {
      display: none;
    }

    .content-container {
      width: 27rem;
    }

    .content_form.content {
      // padding: map_get($design-constant, popup-padding);
      padding: 0 4.5rem 1rem !important;

      .tnc {
        display: none;
      }

      .label {
        padding-top: 20px;
        padding-bottom: 10px;
        text-transform: lowercase !important;
        color: darkgray;
      }

      .content_form-title {
        margin: 10px;
        color: #f3973e;
        text-transform: lowercase !important;
      }
    }
  }

  .items--container__row {
    display: flex;
    justify-content: center;
    padding: 10px;

    .item,
    .child {
      width: 3% !important;
    }

    //justify-content: center;
  }

  .content--selector {
    font-size: map_get($font-list, sm);
    cursor: pointer;
    color: map_get($color_list, bee-primary);
  }
}

/******************************************************************************************************
---------------------------------GLOBAL BUTTON --------------------------------------------------------
******************************************************************************************************/

.button {
  outline: none;
  font-size: map_get($font-list, md);
  border-radius: map_get($design-constant, input_radius);
  padding: map_get($font-list, xs) map_get($font-list, md);
  color: map_get($color_list, light-grey);
  font-family: Nunito, sans-serif;
  border: map_get($design-constant, border-stroke) solid
    map_get($color_list, light-grey);
  height: map_get($design-constant, button_height);
  min-width: 140px;
  text-transform: lowercase !important;
  background: transparent;
  transition: transform 0.2s ease-out;
  cursor: pointer;

  &:hover {
    color: map_get($color_list, secondary);
    background-color: map_get($color_list, bee-primary);
    background-repeat: no-repeat;
    //box-shadow: 0 7px 30px 0px #FF8E26;
    box-shadow: 0 7px 30px 0px rgba(255, 142, 38, 0.5);
    border: 1px solid map_get($color_list, bee-primary);
    //width: 100%;
    transform: translateY(-3px);
  }

  //&:active {
  //  background-color: map_get($color_list, primary);
  //  color: map_get($color_list, secondary);
  //}

  &:disabled {
    background-color: map_get($color_list, primary);
    color: map_get($color_list, secondary);
    transition: none;
    border: none;

    &:hover {
      background-image: none;
      background-color: map_get($color_list, primary);
      color: map_get($color_list, secondary);
      border: none;
      box-shadow: none;
      transition: none;
      transform: none;
    }
  }

  //
  //&:hover:enabled {
  //  background-color: map_get($color_list, bee-primary);
  //  color: map_get($color_list, secondary);
  //  border: map_get($design-constant, border-stroke) solid map_get($color_list, secondary);
  //}

  &.no-border {
    border: 0;
  }

  &.btn-rounded {
    border-radius: 25px;
  }

  &.btn-no-shadow {
    &:hover {
      box-shadow: none;
      color: map_get($color_list, bee-primary) !important;
      background-color: unset !important;
      background-image: unset !important;
    }
  }

  &.active {
    color: map_get($color_list, secondary);
    background-color: map_get($color_list, bee-primary);
    background-repeat: no-repeat;
    border: 1px solid map_get($color_list, bee-primary);
    //width: 100%;
    &:hover {
      //box-shadow: 0 7px 30px 0px #FF8E26;
      box-shadow: 0 7px 30px 0 rgba(255, 142, 38, 0.5);
    }
  }

  &.focused {
    background-color: map_get($color_list, bee-primary);
    color: map_get($color_list, secondary);
    background-repeat: no-repeat;
    border: 1px solid map_get($color_list, bee-primary);
  }

  &--small {
    font-size: map_get($font-list, sm);
    height: 30px;
    min-width: 0;
  }
}

/******************************************************************************************************
---------------------------------GLOBAL INPUT FIELDS ---------------------------------------------------
******************************************************************************************************/
/* Change default bg color of input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px map_get($color_list, input-field-box) inset;
}

.input-field {
  display: flex;
  box-sizing: border-box;
  border-radius: map_get($design-constant, radius);
  background: map_get($color_list, input-field-box);
  font-family: "Nunito", sans-serif;
  font-size: map_get($font-list, md);
  color: rgba(0, 0, 0, 0.75); //map_get($color_list, secondary);
  padding: map_get($design-constant, input-padding-top-bottom)
    map_get($design-constant, input-padding-left-right);
  height: map_get($design-constant, input_height);
  margin-top: 5px;
  border: 0;

  &:focus {
    border: map_get($design-constant, border-stroke) solid
      map_get($color_list, bee-primary);
    background: map_get($color_list, secondary) !important;
    // color: map_get($color_list, bee-primary);
    outline: none;

    + label {
      color: map_get($color_list, bee-primary);
    }
  }

  @each $name, $size in $font-list {
    &.#{$name} {
      font-size: $size;
    }
  }

  @each $name, $height in $design-constant {
    &.#{$name} {
      height: $height;
      width: 100%;
      background: map_get($color_list, input_bg) !important;
    }
  }

  &.no-border {
    border: none;
  }

  &.number_right {
    text-align: right;
  }

  //&.focus {
  //  border-color: map_get($color_list, bee-primary) !important;
  //}
}

.button-container_center {
  display: flex;
  justify-content: center;
}

.page__setup {
  background-image: url("/assets/svg/background_large.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);

  img {
    margin-top: 20%;
    height: 40%;
  }

  h5 {
    font-family: "Nunito", sans-serif;
    font-weight: bolder;
    margin-top: 160px;
    font-size: 14px;
  }
}

.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.home-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.add__padding {
  padding: map_get($design-constant, input_height);
}

.padding-box {
  padding: 15px;
}

// Remove Image container for dynamic form
.content_image {
  display: none !important;
}

//content
/*.content_form {
  max-width: 500px !important;
}*/
.image-form-container {
  max-width: 450px;
  overflow: visible !important;

  .content-container .content {
    flex: 1;
    padding: 0 3rem 1rem !important;
  }
}

.image-form-container .form-logo-container .form-logo {
  height: 100px;
  width: 100px;
  margin-top: -2.2rem;
  background: transparent url("./assets/svg/sharebee-logo.svg") no-repeat !important;
  background-size: contain !important;
}

// Add background to page
.full-page {
  background-image: url("./assets/img/bg-desktop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  align-content: center !important;
  height: 100% !important;
  color: rgba(0, 0, 0, 0.8) !important;

  .transparent-layer {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
}

// Change the Heading style
.content_form-title {
  font-size: map_get($font-list, md1) !important;
  color: map_get($color_list, bee-primary) !important;
  font-family: "montserrat", sans-serif;
  text-transform: lowercase !important;
}

// Align Submit button to center
.button-container {
  margin-top: 35px !important;

  .button {
    max-width: 140px !important;
    outline: none;
  }
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

// Hide forgot password label from login screen
.input-wrapper.anchor--anchor--dynamic-input {
  //div {
  //  padding-top: 3rem;
  //}

  a,
  .anchor {
    text-decoration: map_get($color_list, light-grey) underline;
    font-size: map_get($font-list, small) !important;
    color: map_get($color_list, light-grey) !important;
    //padding-top: 2.2rem;
    cursor: pointer;
  }

  label {
    display: none;
  }
}

.content--container {
  display: flex;
  justify-content: space-evenly;
}

.password-recovery__message {
  padding: 20px;
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-flow: column;

  .child {
    padding: 20px;
    width: 100%;
  }
}

// set width for login screen to take 100% width
app-login {
  app-dynamic-custom-input {
    display: block;
    width: 100%;

    app-dynamic-input {
      width: 100%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .anchor--anchor--dynamic-input {
        padding-top: 2rem;
      }
    }
  }

  .button-container {
    //justify-content: center !important;

    .btn-save {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}

// set width for forgot password screen to take 100% width
app-forgot-password {
  app-dynamic-custom-input {
    display: block;
    width: 100%;

    app-dynamic-input {
      width: 100%;
    }
  }
}

// set width for sign up screen to take 100% width
app-signup {
  app-dynamic-custom-input {
    display: block;
    width: 100%;

    app-dynamic-input {
      width: 100%;

      .tnc .tnc_content {
        input {
          width: 5% !important;
        }
      }
    }
  }
}

// set width for reset password screen to take 100% width
app-reset-password {
  .tnc .tnc_content {
    display: flex;
    align-content: center;
  }

  .tnc .tnc_content input {
    width: 10% !important;
  }

  .tnc .tnc_content span {
    font-size: 0.9rem;
  }

  app-dynamic-custom-input {
    display: block;
    width: 100%;

    app-dynamic-input {
      width: 100%;
    }
  }
}

.app-my-profile {
  lib-app-profile {
    .full-page {
      background-image: none !important;
      background: transparent;
    }
  }
}

lib-app-profile {
  .image-form-container {
    padding-bottom: 30px;

    //max-width: 600px;
    width: 100%;
    max-width: 37rem;
    padding-bottom: 15px;
    overflow: visible !important;
    background: white;
    border-radius: map_get($design-constant, input_radius);

    .logo {
      width: 50px;
    }
  }

  .content_form-title {
    margin-bottom: 0px;
  }

  .submit--button--dynamic-input {
    align-items: center;
    width: 100%;

    label {
      visibility: hidden;
    }

    /*button {
      width: 100%;
      font-size: map_get($font-list, md);
      padding-top: map_get($font-list, xs);
      padding-bottom: map_get($font-list, xs);
      border-radius: map_get($design-constant, input_radius);
      border: 0px;
      outline: none;
      background: map_get($color_list, bee-primary);
      padding-left: map_get($font-list, md);
      padding-right: map_get($font-list, md);
      color: white;
      font-family: 'AbscissaBold';
      //border: map_get($design-constant, border-stroke) solid map_get($color_list, primary);
      height: map_get($design-constant, input_height);
      min-width: 140px;
      text-transform: lowercase !important;

      &:hover {
        background-color: map_get($color_list, primary);
        color: map_get($color_list, secondary);
      }

      &:active {
        background-color: map_get($color_list, primary);
        color: map_get($color_list, secondary);
      }

      &:disabled {
        background-color: map_get($color_list, primary);
        color: map_get($color_list, secondary);
      }

      &:hover:enabled {
        background-color: map_get($color_list, bee-primary);
        color: map_get($color_list, secondary);
        border: map_get($design-constant, border-stroke) solid map_get($color_list, secondary);
      }
    }*/

    button {
      font-family: "Nunito", sans-serif;
      //color: map_get($color_list, secondary);
      //background-color: map_get($color_list, bee-primary);
      //background-repeat: no-repeat;
      //border: 1px solid map_get($color_list, bee-primary);
      //width: 50%;
    }
  }

  .profile_photo--file--dynamic-input {
    .uploader-container {
      div {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
  }

  .content_form-title {
    text-align: center;
  }

  /**************************************** view profile **************************************************************/

  app-dynamic-form {
    > form > app-dynamic-custom-input > .column {
      > app-dynamic-custom-input {
        width: 100%;

        app-dynamic-input {
          width: 100%;
        }

        label {
          padding-bottom: 0.1rem;
        }

        > div > app-dynamic-custom-input {
          &:nth-child(1),
          &:nth-child(2) {
            > div > app-dynamic-custom-input {
              &:nth-child(1) {
                margin-right: 30px;
                flex: auto;

                @media screen and (max-width: $phone-width) {
                  margin-right: 0px;
                }
              }

              &:nth-child(2) {
                width: 8.75rem;

                > div > app-dynamic-custom-input:nth-child(2) {
                  display: flex;
                  flex-grow: 1;
                  align-items: flex-end;
                }
              }
            }
          }
        }

        .input-field {
          font-size: 16px;
        }
      }
    }
  }
}

.profile_photo--file--dynamic-input {
  .uploader-container {
    border: 2px dashed rgba(0, 0, 0, 0.49);
    border-radius: 16px;
    width: 144px;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .custom-tooltip {
      box-sizing: border-box;
      display: none;
      width: 100%;
      text-align: center;
      border-radius: 0 0 15px 15px;

      :hover {
        display: flex;
      }
    }

    :hover {
      .custom-tooltip {
        display: flex;
      }
    }

    .uploader-title {
      padding: 20px;
      font-size: 18px;
      text-overflow: clip;

      position: unset !important;
      width: auto !important;
      height: auto !important;
    }
  }
}

// item creation
.item-file-uploader {
  .uploader-container {
    position: relative;
    height: 100px;
    width: 150px;
    display: flex;

    .retry-overlay {
      border-radius: 16px;
    }

    .image-viewer {
      width: 150px;
      height: 100px;
      border-radius: 16px;

      img {
        border-radius: 16px;
      }
    }

    .uploader-title {
      width: 135px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .image--placeholder {
        background: url("/assets/svg/plus.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
      }

      //display: none;
    }

    .custom-tooltip {
      display: none;

      :hover {
        visibility: hidden;
      }
    }

    :hover {
      .custom-tooltip {
        visibility: hidden;
      }
    }
  }
}

.hive-item-file-uploader {
  .uploader-container {
    position: relative;
    height: 150px;
    display: flex;

    .retry-overlay {
      border-radius: 16px;
    }

    .image-viewer {
      width: 135px;
      height: 150px;
      border-radius: 16px;

      img {
        border-radius: 16px;
      }
    }

    .uploader-title {
      width: 135px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .image--placeholder {
        background: url("/assets/svg/plus.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
      }

      //display: none;
    }

    .custom-tooltip {
      display: none;

      :hover {
        visibility: hidden;
      }
    }

    :hover {
      .custom-tooltip {
        visibility: hidden;
      }
    }
  }
}

.create--item {
  .page-container .box {
    width: 540px;
  }

  .input-field {
    width: 100%;
  }

  .label {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.provide-padding {
  padding: map_get($design-constant, input-padding-top-bottom);
}

// Placeholder
input::placeholder {
  color: map_get($color_list, primary);
  font-size: map_get($font-list, sm1);
}

// create item
.app-create-item {
  file-uploader {
    .uploader-container {
      width: 135px;
    }
  }

  .item-file-uploader .uploader-container .image-viewer {
    width: 135px;

    img {
      object-fit: cover;
    }
  }
}

.create-hive-input {
  file-uploader {
    .uploader-container {
      width: 135px;
    }
  }

  .item-file-uploader .uploader-container .image-viewer {
    width: 135px;

    img {
      object-fit: cover;
    }
  }
}

.hive_button {
  display: flex;
  justify-content: center;
}

.show--cursor {
  cursor: pointer;
}

@media screen and (max-width: $phone-width) {
  lib-app-profile {
    .image-form-container {
      max-width: none;
    }
  }
}

/************************** POPUP Global designs ***************************/
.popup-title {
  font-family: montserrat, sans-serif;
  color: map_get($color_list, bee-primary);
  padding: 5px !important;
}

.popup-contents {
  font-family: Nunito, sans-serif;
  font-size: 0.75rem;
  padding: 10px 0 30px 0 !important;

  .align_center {
    text-align: center;
  }

  &.make-it-black {
    color: map_get($color_list, quad);
  }
}

.item-delete__popup,
.item-booking__popup {
  max-width: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 4rem 2rem 4rem;

  &.hive_item {
    max-width: 24rem !important;
  }

  .popup-title {
    text-align: center;
  }

  .popup-actions {
    display: flex;
    justify-content: space-around;

    > * {
      margin: 10px 20px;
    }
  }
}

// Order Summary
.order-summary-details {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0rem;

  &.add-border {
    border-top: 1px solid map_get($color_list, quad);
  }
}

//ngx-daterangepicker-material {
.md-drppicker {
  &.shown {
    margin: 15px !important;
    -webkit-box-shadow: none !important;

    td &.active {
      transition: background 300ms ease-out;
      background: map_get($color_list, bee-primary);

      &,
      &:hover {
        background-color: map_get($color_list, bee-primary);
        border-color: map_get($color_list, bee-primary);
        color: map_get($color_list, bee-primary);
      }
    }

    &.inline {
      @media screen and (max-width: $phone-width) {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}

//  td:not(.end-date)
//.md-drppicker td.in-range:not(:last-child) {
//  background-color: map_get($color_list, bee-primary) !important;
//  opacity: 0.6 !important;
//  color: map_get($color_list, secondary) !important;
//}

.md-drppicker td.in-range.end-date:last-child {
  background-color: map_get($color_list, bee-primary) !important;
}

.md-drppicker td.in-range:not(.end-date) {
  background-color: map_get($color_list, bee-primary) !important;
  opacity: 0.6 !important;
  color: map_get($color_list, secondary) !important;
}

.md-drppicker td {
  &.active,
  &.start-date,
  &.end-date &.in-range:last-child {
    background-color: map_get($color_list, bee-primary) !important;
  }

  &.off {
    background: map_get($color_list, light-grey-op-1) !important;
  }
}

.md-drppicker table {
  font-family: Nunito !important;
  border-spacing: 0px !important;
}

.md-drppicker {
  /*th.available.prev {
    //background-image: url("../src/assets/svg/dropdown_left.svg") !important;
    //transform: rotate(90deg);
    //background-size: contain;
    fill: map_get($color_list, bee-primary) !important;
    filter: saturate(8);//invert(0.5) sepia(1) saturate(5) hue-rotate(175deg) !important;
    //background-repeat: no-repeat;
  }*/

  .table-condensed {
    .month {
      color: map_get($color_list, bee-primary) !important;
      font-size: map_get($font-list, sm1);
    }
  }
}

.md-drppicker {
  .calendar-table {
    background: map_get($color_list, input-field-box) !important;
  }

  .btn {
    display: none;
  }
}

// verification
.kyc-file-uploader {
  .uploader-container {
    //position: relative;
    height: 100%;
    width: 100%;
    display: flex;

    .image-viewer {
      height: 100%;
      width: 100%;
      padding: 0px !important;
      object-fit: contain;
    }

    .custom-tooltip {
      position: absolute;
      bottom: 0;
      padding: 0px;
      text-align: center;
      width: 100%;
      display: none;
      left: 0;
    }
  }

  div {
    width: 100%;
    height: 100%;
  }

  .uploader-title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

/**************************************** HOMEPAGE ****************************************/
.home-page__label {
  font-family: "Nunito", sans-serif;
  font-size: map_get($font-list, lg);
  color: map_get($color_list, bee-primary);
}

.homepage-inner-wrapper {
  max-width: $item-four-wide-width;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: $phone-width) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.image-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-info-wrapper__info {
  width: 50%;
  font-family: Nunito, sans-serif;

  .info {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 12px;
    font-family: montserrat, sans-serif;
  }
}

.image-info-wrapper__image {
  width: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }
}

.image-info-wrapper__image_about {
  width: 50%;

  img {
    width: 80%;
    height: 80%;
    border-radius: 1rem;
  }
}

@media screen and (max-width: $phone-width) {
  .image-info-wrapper {
    flex-direction: column;
  }

  .image-info-wrapper__info {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .image-info-wrapper__image {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/************************************ CLICK HERE TO UPLOAD SOMETHING BOX *******************************************/
.create-item__tile {
  width: $item-width;
  margin-left: 10px;
  height: 283px; // note, the height here should be calculated
  margin-right: 10px;
  margin-bottom: 3rem;
  border-radius: 6%;
  background: map_get($color_list, background);
  position: relative;
  float: left;

  border: 2px dashed rgba(0, 0, 0, 0.49);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.hive_create_item {
  height: 271px !important;
  margin-right: 15px !important;
}

.create-item__border {
  width: 100%;
  height: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.49);
}

.create-item__icon {
  background: url("../src/assets/svg/plus.svg");
  width: 25px;
  height: 25px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-item-icon {
  background: url("../src/assets/svg/plus.svg");
  width: 20px;
  height: 20px;
}

.create-item_info {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: Nunito, sans-serif;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: $phone-width) {
  .create-item__tile {
    width: 100%;
    margin-bottom: 3rem;
  }
}

/**********************************************Lined titled *******************************************************/
.lined-title {
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    background-color: #333;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

.lined-half-title {
}

/********************************************* shift on hover - GLOBAL ********************************************/
.shift-on-hover {
  transition: transform 0.2s ease-out;
  overflow: visible;

  &:hover {
    transform: translateY(-3px);
    //box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .15);
    transition: box-shadow 0.2s ease-out;
    //border-radius: 6%;
  }
}

// Item details, item deleted
.my-activity-item__popup {
  max-width: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 3rem 2rem 3rem;

  .popup-title {
    text-align: center;
  }

  .my-activity-item__popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
}

/*app-my-list {
  .app-pop-up-layout-container .box {
    padding: 20px;
  }

  .app-pop-up-layout-container .box .logo img {
    width: 75px;
    height: 75px;
    margin-top: -57.5px;
  }

  .popup-actions {
    display: flex;
    justify-content: center;
  }
}*/
/********************************************** No items messages css ***********************************************/

.no-items-message {
  text-align: center;
  text-transform: capitalize;
  font-family: Nunito, sans-serif;
  color: map_get($color_list, bee-primary);
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

// Container
.container-element {
  display: flex;
  flex-direction: column;

  &.center {
    justify-content: center;
    text-align: -webkit-center;
  }

  &.add-margin-05 {
    margin-top: 0.5rem;
  }

  &.add-margin {
    margin-top: 1.5rem;
  }
}

.request-item__container {
  width: 414px;
  padding: 1rem 2rem;
  box-sizing: border-box;
  max-width: 100%;
}

.request-item-list {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;

  .request-item-list__image {
    width: 135px;
    margin: 1%;
    border: 2px dashed rgba(0, 0, 0, 0.49);
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .item-file-uploader .uploader-container .image-viewer {
    width: 135px;

    img {
      object-fit: cover;
    }
  }
}

.account-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.button-margin {
  margin-right: 15px;
}

// Microsoft edge justify-content: space-evenly
@supports (-ms-ime-align: auto) {
  /* Microsoft EdgeV13&14 CSS styles go here */
  .ms-fix-container {
    justify-content: space-around !important;
  }
}

.cheveron_down {
  height: 2rem !important;
  margin-top: 2rem !important;
}

.padding_lr_10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.text-align-center {
  text-align: center;
}
